import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';

export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'index']),
    ...mapState({
      transactionTypes: (state) => state.enums.WarTransTypeEnum,
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      nextWarrantNumber: (state) => state.war.transaction.nextWarrantNumber,
      nextPaymentNumber: (state) => state.war.transaction.nextPaymentNumber,
      fundListByFy: (state) => state.war.transaction.fundListByFy,
    }),
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    filteredWarTransTypes() {
      if (!this.transactionTypes) {
        return [];
      }
      return this.transactionTypes;
    },
    isWarrant() {
      return this.editedItem.type === 'WARRANTS';
    },
    fundsOnly() {
      return this && this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    multipleFunds() {
      return this && this.warSettingItem && this.warSettingItem.multipleFunds;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    getFundId() {
      return this.editedItem.details
      && this.editedItem.details[0]
      && this.editedItem.details[0].fund
      && this.editedItem.details[0].fund._id;
    },
    fundListByFyWise: {
      get() {
        return this.fundListByFy;
      },
      set(value) {
        this.setFundListByFy(value);
      },
    },
  },
  methods: {
    ...mapMutations('war/transaction', [
      'setFundListByFy',
    ]),
    ...mapActions('war/transaction', [
      'loadLastWarrantNo',
      'loadLastPaymentNo',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    determineNextWarrantNo({ fund, fiscalYear }) {
      const loadWarNoPromise = this.loadLastWarrantNo(
        { fund, fiscalYear, baseCrudKey: this.baseCrudKey },
      );
      loadWarNoPromise.then(() => {
        this.editedItem.warrant = this.nextWarrantNumber;
      });
    },
    determineNextPaymentNo({ fund, fiscalYear }) {
      const loadPaymentNoPromise = this.loadLastPaymentNo(
        { fund, fiscalYear, baseCrudKey: this.baseCrudKey },
      );
      loadPaymentNoPromise.then(() => {
        this.editedItem.payment = this.nextPaymentNumber;
      });
    },
    handlingTabOrder(e) {
      if (this.editedItem.details && this.editedItem.details[0]) {
        if (this.editedItem.details[0].fund === null) {
          e.preventDefault();
          document.getElementById('war-trans-fund-0').focus();
        } else if (!this.fundsOnly && (this.editedItem.details[0].department === null)) {
          e.preventDefault();
          document.getElementById('war-trans-dept-0').focus();
        } else if (!this.fundsOnly && (this.editedItem.details[0].account === null)) {
          e.preventDefault();
          document.getElementById('war-trans-acc-0').focus();
        } else {
          e.preventDefault();
          document.getElementById('war-trans-amt-0').focus();
        }
      }
      return false;
    },
    async loadFundList() {
      const { fiscalYear } = this.editedItem;
      const fundObjs = await this.loadFunds({
        criteria: {
          fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
      });
      const updatedFundObj = (fundObjs || []).map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      this.fundListByFyWise = [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];

      /* on successfully loading of funds */
      const { enablePrintWarrant } = (this.warSettingItem || {});
      if (enablePrintWarrant) {
        this.onChangeFund();
      }

      return updatedFundObj;
    },
  },
};
