/*
  * This mixin is very specific for warrants program transactionList
  * It is a kind of war-transactionList extension
  * Be careful before mixin it anywhere else
*/

import { mapActions } from 'vuex';
import {
  toFiscalYear,
  toIsoDate,
  updateDateFormat,
} from '../../../util/shared/tmc-global';
import {
  extractId,
  defaultOverlayConfig,
  incrementOverlay,
} from '../../../util/shared/vue-global';
import {
  warDetailsElements,
  dateForCriteria,
  createWarTransactionGroup,
} from './warTransaction.util';

const buildWarDetailsForReq = (detail) => {
  const warDetail = { ...detail };
  warDetailsElements.forEach((element) => {
    if (detail[element]) {
      warDetail[element] = extractId(warDetail[element]);
    }
  });
  return warDetail;
};

const updateAmountToZero = (item) => {
  const updatedItem = { ...item };
  updatedItem.amount = 0.00;
  updatedItem.payment = null;
  //  also zero the amounts on details.funds[]
  updatedItem.details = (updatedItem.details || []).map((element) => {
    const updatedElement = { ...element };
    updatedElement.amount = 0.00;
    return updatedElement;
  });
  return updatedItem;
};

export default {
  methods: {
    incrementOverlay,
    ...mapActions(['flashInfo', 'flashSuccess']),
    ...mapActions('war/transaction', [
      'warStatsImportSummary',
    ]),
    setDefaultData() {
      this.markingDateForItems = this.todaysDate;
    },
    getChuckID() {
      return ((this.$route || {}).query || {}).chunkID;
    },
    async fetchAmountTotal() {
      const chunkID = this.getChuckID();
      if (chunkID) {
        const result = await this.warStatsImportSummary({
          criteria: {
            chunkID,
          },
          programKey: this.baseCrudKey,
        });
        this.sumOfTrans.totalAmt = result.totalAmount;
        this.sumOfTrans.totalCount = result.count;
        this.sumOfTrans.isDisplay = true;
      }
    },
    handleQuery() {
      const chunkID = this.getChuckID();
      const paramsCriteria = {};
      if (chunkID) {
        paramsCriteria.chunkID = chunkID;
        // for bulk import set also set FY criteria (all years)
        paramsCriteria.fiscalYear = 0;
      }
      return paramsCriteria;
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
      this.criteriaState = { ...this.criteriaState, ...this.handleQuery() };
      if (this.defaultTransType) {
        this.criteriaState.type = this.defaultTransType;
      }
      this.criteriaState = updateDateFormat(this.criteriaState, dateForCriteria);
    },
    headersCriteria() {
      this.loadConfig({ requiredScope: 'warconfig' }).then(() => {
        const type = this.criteriaState.type || 'WARRANTS';
        this.setHeadersBySetting(type);
      }).catch((e) => {
        console.warn(e);
      });
    },
    setSortByType() {
      if (Array.isArray(this.criteriaState.sortBy) && this.criteriaState.sortBy[0]) {
        if (this.criteriaState.type === 'WARRANTS') {
          const isSortByWarrant = (this.criteriaState.sortBy || [])
            .find((item) => item.column === 'warrant');
          // check sortBy contains warrant column, already
          if (!isSortByWarrant) {
            this.criteriaState.sortBy.push({
              column: 'warrant',
              direction: 'desc',
            });
          }
        } else {
          this.criteriaState.sortBy = (this.criteriaState.sortBy || [])
            .filter((item) => item.column !== 'warrant');
        }
      }
    },
    updateCriteriaForReq() {
      const updatedCriteria = updateDateFormat(this.criteriaState, dateForCriteria);
      return updatedCriteria;
    },
    setDefaultSelectedItems(criteriaArgs = {}) {
      if (criteriaArgs.searchMode === 'wildcard') {
        this.$nextTick(() => {
          this.selectedItems = [...this.selectedItems];
        });
      } else {
        this.$nextTick(() => {
          this.selectedItems = [];
        });
      }
    },
    duplicateAppr() {
      if (window.confirm(`Are you sure want to duplicate ${(this.selectedItems || []).length} record/s?`)) {
        const isoData = toIsoDate(this.markingDateForItems);
        for (let i = 0; i < (this.selectedItems || []).length; i += 1) {
          const upsItem = JSON.parse(JSON.stringify(this.selectedItems[i]));
          if (upsItem.type === 'APPROPRIATIONS') {
            delete upsItem._id;
            delete upsItem.hasFiles;
            upsItem.registerDate = isoData;
            upsItem.issueDate = isoData;
            upsItem.amount = 0.00;
            upsItem.details = (upsItem.details || []).map((element) => {
              const updatedElement = buildWarDetailsForReq(element);
              updatedElement.amount = 0.00;
              return updatedElement;
            });
            this.upsrtTransaction({ baseCrudKey: this.baseCrudKey, item: upsItem }).then(() => {
              this.setDefaultSelectedItems();
            }).catch((e) => {
              console.warn(e);
              this.setDefaultSelectedItems();
            });
          }
        }
        this.criteriaState.fromRegisterDate = isoData;
        this.criteriaState.toRegisterDate = isoData;
        this.$nextTick(() => {
          this.handleLoadCRUDList();
        });
      }
    },
    async getPaymentNumber(item) {
      const { fiscalYear } = item;
      const fund = extractId(item.details[0].fund);
      let paymentNumber = 0;
      if (fund && fiscalYear) {
        paymentNumber = (((await this.loadLastPaymentNo({
          fund, fiscalYear, baseCrudKey: this.baseCrudKey,
        })).warStatsMaxPayment || {}).max || 0) + 1;
      }
      return paymentNumber;
    },
    async itemsBulkUpdate(statusMark) {
      if (window.confirm(`Are you sure want to update ${(this.selectedItems || []).length} record/s?`)) {
        this.overlay.flag = true;
        const transForBulkMarking = [...this.selectedItems];
        const transLength = (transForBulkMarking || []).length;
        for (let i = 0; i < transLength; i += 1) {
          let item = JSON.parse(JSON.stringify(transForBulkMarking[i]));
          if (item.status !== statusMark && this.warMustOSForChangingStatus(item)) {
            item.status = statusMark;
            if (statusMark === 'PAID') {
              item.paidCancelledDate = this.markingDateForItems;
              item.payment = await this.getPaymentNumber(item);
            } else if (statusMark === 'VOID') {
              item = updateAmountToZero(item);
            } else if (statusMark === 'CANCELLED') {
              item.paidCancelledDate = this.markingDateForItems;
              // Todo - cleanup#war-cancelled-amount
              if (this.warSettingItem.cancelledWarrantAmountFlag) {
                // Nothing
              } else {
                item = updateAmountToZero(item);
              }
            }
            await this.upsertTransaction(item, true);
          } else {
            this.flashInfo('One or more transactions were already paid/cancelled/void.');
          }
          incrementOverlay(this, transLength, i);
        }
        this.overlay = defaultOverlayConfig();
        this.selectedItems = [];
      }
    },
    async markUnpaid() {
      const isoData = toIsoDate(this.markingDateForItems);
      const response = await this.fetchUnpaidTrans();
      const responseLength = (response || []).length;
      if (responseLength === 0) {
        this.flashInfo(`On ${isoData} date nothing needs to be unpaid.`);
      } else
      if (window.confirm(`Are you sure want to unpay ${responseLength} record/s?`)) {
        this.overlay.flag = true;
        for (let i = 0; i < responseLength; i += 1) {
          const item = response[i];
          const upsItem = {
            _id: item._id,
            status: 'OUTSTANDING',
            paidCancelledDate: null, // for date datatype
            payment: null,
          };
          await this.upsertTransaction(upsItem, true);
          incrementOverlay(this, responseLength, i);
        }
        this.overlay = defaultOverlayConfig();
        this.flashSuccess('Record/s marked Unpaid.');
        this.selectedItems = [];
      }
    },
    async markNonPayable() {
      if (window.confirm(`Are you sure want to update ${(this.selectedItems || []).length} record/s?`)) {
        this.overlay.flag = true;
        const transForBulkMarking = [...this.selectedItems];
        const transLength = (transForBulkMarking || []).length;
        for (let i = 0; i < transLength; i += 1) {
          const item = JSON.parse(JSON.stringify(transForBulkMarking[i]));
          if ((!item.isNonPayable)) {
            item.isNonPayable = true;
            await this.upsertTransaction(item, true);
          } else {
            this.flashInfo('One or more transactions were already marked non-payable.');
          }
          incrementOverlay(this, transLength, i);
        }
        this.overlay = defaultOverlayConfig();
        this.selectedItems = [];
      }
    },
    async getPayment(groupedTrans = {}) {
      const updatedTransWithPayment = [];
      for (const [key, value] of Object.entries(groupedTrans)) {
        const keyObj = JSON.parse(key);
        const { fiscalYear, fundId } = keyObj;
        let paymentNumber = 0;
        if (fundId && fiscalYear) {
          paymentNumber = (((await this.loadLastPaymentNo({
            fund: fundId, fiscalYear, baseCrudKey: this.baseCrudKey,
          })).warStatsMaxPayment || {}).max || 0) + 1;
        }
        for (let i = 0; i < (value || []).length; i += 1) {
          const updateItem = JSON.parse(JSON.stringify(value[i]));
          updateItem.status = 'PAID';
          updateItem.paidCancelledDate = this.markingDateForItems;
          updateItem.payment = paymentNumber;
          await this.upsertTransaction(updateItem, true);
        }
      }
      return updatedTransWithPayment;
    },
    async markItemsPaid() {
      if (window.confirm(`Are you sure want to update ${(this.selectedItems || []).length} record/s?`)) {
        this.overlay.flag = true;
        const checkTrans = this.selectedItems.some((el) => el.type === 'WARRANTS'
           && el.status === 'OUTSTANDING');
        if (checkTrans) {
          const groupedTrans = createWarTransactionGroup(this.selectedItems);
          await this.getPayment(groupedTrans);
        } else {
          this.flashInfo('One or more transactions were already paid/cancelled/void.');
        }
        this.overlay = defaultOverlayConfig();
        this.selectedItems = [];
      }
    },
    onChangeEditDialogFund(fundNumberList) {
      const { fundListForPrintWarrant } = (this.warSettingItem || {});
      const someFundMatched = (fundListForPrintWarrant || []).find(
        (item) => fundNumberList.some((f) => f === item),
      );
      if (someFundMatched) {
        this.showWarPrintFormBtn = true;
      } else {
        this.showWarPrintFormBtn = false;
      }
    },
  },
};
