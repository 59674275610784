<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="50vw"
      >
        <v-card>
          <v-card-title class="text-h5">
            Transactions Successfully Imported
          </v-card-title>
          <v-card-text>
            <ul>
              <li>Number of Transactions: {{sumOfTrans.totalCount}}</li>
              <li>Total Amount: {{(amountFormat(sumOfTrans.totalAmt))}}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { amountFormat } from '../../../util/shared/vue-global';

export default {
  name: 'SummaryDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sumOfTrans: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    amountFormat,
  },
};
</script>

<style>

</style>
