<template>
  <div class="war-appr-common">
    <div>
      <v-dialog
        v-model="isDialog"
        persistent
        max-width="60vw">
        <v-card>
          <v-card-title>
            <span class="headline">
            <span v-html="dialogHeader"></span>
            </span>
          </v-card-title>
          <v-card-text>
            <v-btn
              color="blue darken-1"
              text
              @click="goToFundsPage"
            >
              Yes
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-row>
      <v-col cols="2">
        <v-text-field
          :readonly="isFYReadOnly"
          :rules="reqdSelectValidation"
          v-model.number="editedItem.fiscalYear"
          label="Fiscal Year"
          type="number"
          @blur="determineTransactionNo(); onChangeFy();"
          :disabled="true"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          readonly
          id="edited-item-type"
          v-model="editedItem.type"
          :items="filteredWarTransTypes"
          :rules="reqdSelectValidation"
          item-text="description"
          item-value="name"
          label="Type"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <ClarionDateControl
          v-model="editedItem.registerDate"
          :isIsoDate="true"
          :rules="reqdSelectValidation"
          :label="`Register Date`"
        />
      </v-col>
      <v-col cols="3">
        <ClarionDateControl
          v-model="editedItem.issueDate"
          :isIsoDate="true"
          :rules="reqdSelectValidation"
          :label="`Issue Date`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" v-if="isWarrant">
        <GetMaxNumberInput class="warrant"
          v-model.number="editedItem.warrant"
          :fund="getFundId"
          :fiscalYear="editedItem.fiscalYear"
          :isNewItem="isNewItem"
          :baseCrudKey="baseCrudKey"
          fieldType="Warrant No"
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="editedItem.whomStr"
          :items="whoms"
          :rules="reqdSelectValidation"
          label="To Whom"
          autofocus
          @keydown="whomShortcutHandler"
          @update:list-index="whomUpdateSearchInputByNumber"
          @update:search-input="whomUpdateSearchInput"
          @focus="onFocusAutocomplete "
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <h5>Amount</h5>
        <div>{{editedItem.amount}}</div>
      </v-col>
      <v-col cols="2" v-if="isWarrant">
        <v-checkbox v-model="editedItem.isEftCode" label="EFT"></v-checkbox>
      </v-col>
      <v-col cols="2" v-if="isWarrant">
        <h5>PO Number</h5>
        <div>{{editedItem.purchaseOrder || '_________________'}}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import WarrantAppropriationsMixin from './WarrantAppropiations.mixin';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import GetMaxNumberInput from './GetMaxNumberInput.vue';
import {
  onFocusAutocomplete,
  checkStringEmptiness,
  extractId,
} from '../../../util/shared/vue-global';

export default {
  name: 'WarApprCommon',
  mixins: [WarrantAppropriationsMixin],
  components: {
    ClarionDateControl,
    GetMaxNumberInput,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'war-dialog',
    },
  },
  data: () => ({
    whomLastSearched: undefined,
    fyDialog: false,
    dialogHeader: '',
  }),
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'index']),
    ...mapState({
      warTransStatus: (state) => state.enums.WarTransStatusEnum,
    }),
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    whoms() {
      const whomList = (this.$store.state.base.crud.items.warWhoms || []).map(
        (w) => w.description || '',
      );
      whomList.push(this.editedItem.whomStr || '');
      return whomList;
    },
    isDialog: {
      get() {
        return this.fyDialog;
      },
      set(value) {
        this.$emit('dialogVal', value);
      },
    },
    isFYReadOnly() {
      if (this.isNewItem) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onFocusAutocomplete,
    ...mapActions('war/whom', ['loadWhoms', 'upsrtWhom']),
    ...mapActions([
      'flashError',
      'flashInfo',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapMutations('base/crud', ['setCriteria', 'setItem', 'setIsVisible']),
    ...mapMutations('war/transaction', [
      'setWarrantRegPolling',
    ]),
    whomUpdateSearchInput(val) {
      this.whomLastSearched = val;
    },
    whomUpdateSearchInputByNumber(number) {
      this.whomLastSearched = this.whoms[number];
    },
    async whomShortcutHandler(e) {
      if (e.key === 'Tab') {
        const { whomStr } = this.editedItem;
        const { whomLastSearched } = this;
        const whmBaseCrudKey = 'warWhoms';
        const fields = {
          _id: '',
          description: '',
          fiscalYear: '',
        };
        const matchWhoms = this.whoms.filter((w) => w === whomStr);
        const matchWhomLastSearched = this.whoms.filter(
          (w) => w === whomLastSearched,
        );
        if (matchWhoms.length > 0 || whomLastSearched) {
          const matchWhom = matchWhoms[0] ? matchWhoms[0] : '';
          if (
            (matchWhom || '').toLowerCase()
              !== (whomLastSearched || '').toLowerCase()
            && matchWhomLastSearched.length === 0
          ) {
            this.flashInfo('Creating a new Whom entry.');
            // create a new record to match the description typed
            const item = {
              description: whomLastSearched,
              fiscalYear: this.editedItem.fiscalYear,
            };
            await this.upsrtWhom({
              item,
              fields,
              baseCrudKey: whmBaseCrudKey,
            });
            await this.loadWhoms({
              criteria: {},
              fields,
              baseCrudKey: whmBaseCrudKey,
            });
          }
          this.editedItem.whomStr = whomLastSearched;
        }
        this.handlingTabOrder(e);
      }
    },
    determineTransactionNo() {
      if (this.editedItem.type === 'WARRANTS') {
        this.setWarrantRegPolling(undefined);
        const warrantTransPolling = setInterval(
          this.getTransNo,
          1200,
        );
        this.setWarrantRegPolling(warrantTransPolling);
      }
    },
    getTransNo() {
      const fundId = extractId(this.editedItem.details[0].fund);
      const { fiscalYear } = this.editedItem;
      if (!(checkStringEmptiness(fundId) || checkStringEmptiness(fiscalYear))) {
        this.determineNextWarrantNo(
          {
            fund: fundId,
            fiscalYear,
          },
        );
        if (this.editedItem.status === 'PAID') {
          this.determineNextPaymentNo(
            {
              fund: fundId,
              fiscalYear,
            },
          );
        }
      }
    },
    async onChangeFy() {
      const { fiscalYear } = this.editedItem;
      if (!(fiscalYear == null) && fiscalYear !== '') {
        const funds = await this.loadFundList();
        if (Array.isArray(funds) && (funds.length === 0)) {
          this.fyDialog = true;
          this.dialogHeader = `No Funds available for ${this.editedItem.fiscalYear}.
          Do you want to create new Funds?`;
        }
      }
    },
    goToFundsPage() {
      this.setIsVisible([this.baseCrudKey, false]);
      // Todo - update for payroll
      this.$router.push({ name: 'Warrant/Payroll Register - Create Fiscal Year' });
    },
    close() {
      this.fyDialog = false;
    },
  },
};
</script>
