<template>
 <div>
   <CRUDCriteria
    :showReset="true"
    :showSearch="false"
    >
    <template v-slot:criteriaRows>
      <v-row>
        <v-col cols="3">
          <v-select id="war-criteria-type"
          v-model="criteriaState.type"
          :items="warTransTypes"
          item-text="description"
          item-value="name"
          label="By Type"
          @change="searchByCriteria"
          >
          </v-select>
        </v-col>
        <!-- using v-select because MultiItemSelect component has issues -->
        <!-- <v-col cols="3">
          <v-select id="war-criteria-multiselect-funds"
            v-model="criteriaState.funds"
            class="war-reports-by-fund-list-multiselect"
            label="By Fund"
            item-text="fundDesc"
            item-value="_id"
            :items="funds"
            @change="searchByCriteria"
            multiple
            >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.fundDesc }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ criteriaStateFundLength - 1 }} others)
              </span>
            </template>
            <template v-slot:prepend-item>
            <v-list-item
              ripple
              @click="toggle"
            >
              <v-list-item-action>
                <v-icon :color="criteriaStateFundLength > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col> -->
        <v-col cols="3">
          <v-select id="war-criteria-fund"
          v-model="criteriaState.fund"
          item-text="fundDesc"
          item-value="_id"
          :items="funds"
          label="By Fund"
          @change="searchByCriteria"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select id="war-criteria-department"
          v-model="criteriaState.department"
          item-text="deptDesc"
          item-value="_id"
          :items="departments"
          label="By Department"
          v-if="!isFundsOnly"
          @change="searchByCriteria"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select id="war-criteria-account"
          v-model="criteriaState.account"
          item-text="accDesc"
          item-value="_id"
          :items="accounts"
          v-if="!isFundsOnly"
          label="By Account"
          @change="searchByCriteria"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
      <v-col cols="3">
        <FySelect
          :fyRange="warFiscalYears"
          v-model="criteriaState.fiscalYear"
          @input="searchByCriteria(); loadFundList();"
          />
      </v-col>
        <v-col cols="3" v-if="isTypeWarrant">
          <v-select id="war-criteria-status"
          v-model="criteriaState.status"
          :items="warTransStatus"
          item-text="description"
          item-value="name"
          label="Status"
          @change="searchByCriteria"
        ></v-select>
        </v-col>
        <v-col cols="2" v-if="isTypeWarrant">
        <v-select
          v-model="criteriaState.isEftCode"
          id="war-criteria-eft-code"
          :items="isEftCodeOptions"
          item-text="caption"
          item-value="value"
          label="EFT"
          @change="searchByCriteria"
          >
        </v-select>
        </v-col>
      <v-col cols="4">
        <v-text-field
          id="transactionWildCard"
          label="Wildcard search (warrant #, amount or to whom)"
          v-model="criteriaState.wildcard"
          @input="wildcardSearchDelayed"
          autocomplete="off"
        ></v-text-field>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <ClarionDateControl
            v-model="criteriaState.fromRegisterDate"
            :isRequired="false" @blurred="searchByCriteria" :label="`From (Registered)`" />
        </v-col>
        <v-col cols="3">
          <ClarionDateControl
            v-model="criteriaState.toRegisterDate"
            :isRequired="false" @blurred="searchByCriteria" :label="`To`" />
        </v-col>
        <v-col cols="3">
          <ClarionDateControl
            v-model="criteriaState.fromPaidCancelledDate"
            :isRequired="false" @blurred="searchByCriteria" :label="`From (Paid)`" />
        </v-col>
        <v-col cols="3">
          <ClarionDateControl
            v-model="criteriaState.toPaidCancelledDate"
            :isRequired="false" @blurred="searchByCriteria" :label="`To`" />
        </v-col>
      </v-row>
    </template>
    </CRUDCriteria>
 </div>
</template>
<script>
import {
  mdiSelectAll,
  mdiCloseBox,
  mdiCheckboxBlankOutline,
  mdiMinusBox,
} from '@mdi/js';

import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';
import { calculateFiscalYears } from '../../../util/shared/tmc-global';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import { headersByType } from './transaction.headers';
import FySelect from '../../common/FySelect.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';

const fundCrudKey = 'sharedFund';
const departmentCrudKey = 'warDepartment';
const whomCrudKey = 'warWhoms';
const accountCrudKey = 'warAccount';

export default {
  name: 'TransactionCriteria',
  components: {
    CRUDCriteria,
    FySelect,
    ClarionDateControl,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'transactionCriteria',
    },
  },
  data: () => ({
    delayTimerId: undefined,
    icons: {
      mdiSelectAll,
      mdiCloseBox,
      mdiCheckboxBlankOutline,
      mdiMinusBox,
    },
  }),
  created() {
    this.intializeCriteria();
    this.loadConfig({ requiredScope: 'warconfig' });
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapGetters([
      'todaysDate',
    ]),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      transactionTypes: (state) => state.enums.WarTransTypeEnum,
      warTransStatus(state) {
        return state.enums.WarTransStatusEnum === undefined ? undefined : [{
          description: '',
          name: null,
        }, ...state.enums.WarTransStatusEnum];
      },
      warFiscalYears() {
        const registerdYears = (this.$store.state.war.transaction.regYears || {});
        return calculateFiscalYears(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    warTransTypes() {
      if (!this.transactionTypes) {
        return [];
      }
      return this.transactionTypes;
    },
    isTypeWarrant() {
      if (this.criteriaState.type === 'WARRANTS') {
        return true;
      }
      return false;
    },
    funds() {
      const fundObjs = this.items(fundCrudKey) || [];
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      return [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    departments() {
      const departmentObjs = this.items(departmentCrudKey) || [];
      const updatedDeptObj = departmentObjs.map((d) => ({
        ...d,
        deptDesc: `${d.dept} ${d.description || ''}`,
      }));
      return [{ _id: null, dept: '', deptDesc: '' }, ...updatedDeptObj];
    },
    accounts() {
      const accountObjs = this.items(accountCrudKey) || [];
      const updatedAccObjs = accountObjs.map((a) => ({
        ...a,
        accDesc: `${a.account} ${a.shortDesc || ''}`,
      }));
      return [{ _id: null, account: '', accDesc: '' }, ...updatedAccObjs];
    },
    // isAllFundsSelected() {
    //   return this.criteriaStateFundLength === this.funds.length;
    // },
    // isSomeFundsSelected() {
    //   return this.criteriaStateFundLength > 0 && !this.isAllFundsSelected;
    // },
    icon() {
      if (this.isAllFundsSelected) return this.icons.mdiCloseBox;
      if (this.isSomeFundsSelected) return this.icons.mdiMinusBox;
      return this.icons.mdiCheckboxBlankOutline;
    },
    isEftCodeOptions() {
      return [
        {
          value: null,
          caption: '(any)',
        },
        {
          value: false,
          caption: 'No',
        },
        {
          value: true,
          caption: 'Yes',
        },
      ];
    },
    isFundsOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    // criteriaStateFundLength() {
    //   if (Array.isArray(this.criteriaState.funds)) {
    //     return this.criteriaState.funds.length;
    //   }
    //   return 0;
    // },
  },
  methods: {
    ...mapMutations('war/transaction', [
      'setHeaders',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    ...mapActions('war/whom', ['loadWhoms']),
    ...mapActions('war/account', ['loadAccounts']),
    ...mapActions('war/department', ['loadDepartments']),
    ...mapActions('shared/fund', ['loadFunds']),
    ...mapActions('SystemConfig', ['loadConfig']),
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);
      // delay new call
      this.delayTimerId = setTimeout(async () => {
        const criteriaArgs = { searchMode: 'wildcard' };
        await this.handleLoadCRUDList(criteriaArgs);
      }, 250);
    },
    searchByCriteria() {
      this.setHeadersBySetting();
      this.handleLoadCRUDList();
    },
    handleLoadCRUDList(criteriaArgs = {}) {
      this.$emit('loadCRUDList', criteriaArgs);
    },
    mapCollectionVal(itemList, itemValue) {
      return [...(itemList || []).map((item) => item[`${itemValue}`])];
    },
    loadFundList() {
      this.loadFunds({
        criteria: {
          fiscalYear: this.criteriaState.fiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: fundCrudKey,
      });
    },
    selectAllFunds() {
      this.criteriaState.funds = [...this.funds.map((f) => f._id)];
    },
    setHeadersBySetting() {
      if (this.isFundsOnly) {
        const allHeaders = headersByType[this.criteriaState.type];
        const filteringAccount = allHeaders.filter((o) => (o.value !== 'account') && (o.value !== 'department'));
        this.setHeaders(filteringAccount);
      } else {
        this.setHeaders(headersByType[this.criteriaState.type]);
      }
    },
    dataMapper(dataItems) {
      const headersField = [];
      for (let i = 0; i < dataItems.length; i += 1) {
        headersField.push(dataItems[i]);
        if (Array.isArray(dataItems[i].details) && dataItems[i].details.length > 0) {
          headersField[i].fund = dataItems[i].details[0].fund;
          headersField[i].amount = dataItems[i].details[0].amount;
          headersField[i].account = dataItems[i].details[0].account;
        }
      }
      return headersField;
    },
    intializeCriteria() {
      const whomFields = {
        _id: '',
        description: '',
        fiscalYear: '',
      };
      this.loadWhoms({
        criteria: {},
        fields: whomFields,
        baseCrudKey: whomCrudKey,
      });
      const departmentFields = {
        _id: '',
        dept: '',
        description: '',
      };
      this.loadDepartments({
        criteria: {
          sortBy: [
            {
              column: 'dept',
              direction: 'asc',
            },
          ],
        },
        fields: departmentFields,
        baseCrudKey: departmentCrudKey,
      });
      const accountFields = {
        _id: '',
        account: '',
        shortDesc: '',
      };
      this.loadAccounts({
        criteria: {
          sortBy: [
            {
              column: 'account',
              direction: 'asc',
            },
          ],
        },
        fields: accountFields,
        baseCrudKey: accountCrudKey,
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllFundsSelected) {
          this.criteriaState.funds = [];
        } else {
          this.selectAllFunds();
        }
        this.$nextTick(() => {
          this.searchByCriteria();
        });
      });
    },
  },
};
</script>
