<template>
    <v-container>
      <h3>{{ getProgramDetails.programName || '' }}</h3>
      <TransactionList v-if="!reload"
      :baseCrudKey="getProgramDetails.baseCrudKey"/>
    </v-container>
</template>

<script>
import TransactionList from '@/components/War/Transaction/TransactionList.vue';
import { getProgramDetailsByParams } from '@/components/War/Transaction/warTransaction.util';

export default {
  name: 'Warrants',
  components: {
    TransactionList,
  },
  data: () => ({
    reload: false,
  }),
  watch: {
    getProgramDetails() {
      this.reload = true;
      this.$nextTick(() => {
        this.reload = false;
      });
    },
  },
  computed: {
    getProgramDetails() {
      const [programDetails] = getProgramDetailsByParams(this);
      return programDetails;
    },
  },
};
</script>
