<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-bind="attrs" v-on="on"
        v-model="getMaxNoInputLocal"
        :label="inputLabel"
        :readonly="!inputFieldOverride || isNewItem"
        @dblclick.stop="overrideMaxNumber"
        color="red"
        type="number"
        id="max-number-input"
        :class="warrantRegPulseClass"
      ></v-text-field>
    </template>
    <span>{{ inputHoverCaption }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'GetMaxNumberInput',
  props: {
    fund: String,
    fiscalYear: [Number, String],
    fieldType: {
      type: String,
      default: 'Item',
    },
    warrant: [Number, String],
    isNewItem: Boolean,
    baseCrudKey: {
      type: String,
      default: 'maxNumberInput',
    },
  },
  data: () => ({
    inputFieldOverride: false,
  }),
  model: {
    prop: 'warrant',
    event: 'maxNumberChg',
  },
  created() {
    if (this.fund && this.fiscalYear) {
      this.loadLastWarrantNo({
        fund: this.fund,
        fiscalYear: this.fiscalYear,
        baseCrudKey: this.baseCrudKey,
      });
    }
  },
  computed: {
    ...mapState({
      nextWarrantNumber: (state) => state.war.transaction.nextWarrantNumber,
      warrantRegPulse: (state) => state.war.transaction.warrantRegPulse,
    }),
    ...mapGetters('base/crud', [
      'item',
    ]),
    warrantRegPulseClass() {
      return this.warrantRegPulse ? 'color-anim-pulse' : 'color-anim-black';
    },
    inputLabel() {
      if (this.nextWarrantNumber === undefined) {
        return `${this.fieldType} # (last: ...)`;
      }
      return `${this.fieldType} # (last: ${this.nextWarrantNumber - 1})`;
    },
    getMaxNoInputLocal: {
      get() {
        return this.warrant;
      },
      set(value) {
        this.$emit('maxNumberChg', value);
      },
    },
    inputHoverCaption() {
      if (this.isNewItem) {
        return `${this.fieldType}  must be saved before its number can be changed`;
      }
      return 'Double-click to override (use ⚠️ caution)';
    },
  },
  methods: {
    ...mapActions('war/transaction', ['loadLastWarrantNo']),
    overrideMaxNumber() {
      if (!this.inputFieldOverride) {
        this.inputFieldOverride = true;
      }
    },
  },
};
</script>
