<template>
 <v-data-table
    dense
    :items="editedItem"
    :headers="headerList"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 war-appr-table"
  >
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          class="mx-2" color="primary"
          fab icon x-small
          v-if= "isMultipleFunds"
          @click="addOne"
        >
          <v-icon>
            {{icons.mdiPlus}}
          </v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:[`item.fund`]="{ item }">
      <v-select
        v-model="item.fund"
        :items="funds"
        :rules="reqdSelectValidation"
        item-text="fundDesc"
        item-value="_id"
        dense
        single-line
        class="war-trans-fund"
        :id="`war-trans-fund-${editedItem.indexOf(item)}`"
        @blur="somethingChanged"
        @change="somethingChanged(); onChangeFund();
        updateStatsBalance(item, warStatsBalanceParams().fundBalance);"
      ></v-select>
    </template>
    <template  v-slot:[`item.department`]="{ item }">
      <v-select
      v-model="item.department"
        v-if="!isFundsOnly"
        :items="departments"
        item-text="deptDesc"
        :id="`war-trans-dept-${editedItem.indexOf(item)}`"
        item-value="_id"
        dense
        single-line
        @blur="somethingChanged"
      ></v-select>
    </template>

    <template v-slot:[`item.account`]="{ item }">
      <v-select
      v-model="item.account"
        :items="accounts"
        :id="`war-trans-acc-${editedItem.indexOf(item)}`"
        item-text="accDesc"
        item-value="_id"
        dense
        single-line
        rows="1"
        ref="warAccount"
        v-if="!isFundsOnly"
        @blur="somethingChanged"
        @change="updateStatsBalance(item, warStatsBalanceParams().accountBalance);"
      ></v-select>
    </template>

    <template v-slot:[`item.amount`]="{ item, index }">
      <v-text-field
      v-model="item.amount"
        :rules="reqdSelectValidation"
        :id="`war-trans-amt-${editedItem.indexOf(item)}`"
        dense
        single-line
        class="trans-amount"
        @blur="somethingChanged"
        @keydown="shortcutHandler($event, item, index)"
        @focus="focusAmount"
        @keydown.enter="somethingChanged"
      ></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small class="mr-2 war-trans-item-delete"
        tabindex="-1"
        @click="removeOne(item)"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </template>

    <template slot="body.append">
      <tr class="blue--text">
          <td colspan="2" id="total-trans-amount">
            Total : ${{ amountFormat(sumAmount) }}
          </td>
      </tr>
    </template>

  </v-data-table>
</template>
<script>
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import {
  focusAmount,
  amountFormat,
  amountToNumber,
  isTabOrPlus,
  checkStringEmptiness,
  extractId,
} from '../../../util/shared/vue-global';
import { warStatsBalanceParams } from './warTransaction.util';

const defaultItem = {
  fund: null,
  department: null,
  account: null,
  amount: 0,
};
const departmentCrudKey = 'warDepartment';
const accountCrudKey = 'warAccount';

export default {
  name: 'WarApprDetails',
  props: {
    baseCrudKey: {
      type: String,
      default: 'war-dialog',
    },
    value: {
      type: Array,
      default: () => [],
    },
    activeTransItem: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.loadFundAndAccBal();
  },
  watch: {
    fundLabel() {
      this.headers = this.headers.map((h) => {
        if (h.name === 'fundCol') {
          return {
            ...h,
            text: this.fundLabel,
          };
        }
        return h;
      });
    },
    accountLabel() {
      this.headers = this.headers.map((h) => {
        if (h.name === 'accCol') {
          return {
            ...h,
            text: this.accountLabel,
          };
        }
        return h;
      });
    },
    isVisibleProp() {
      if (this.isVisibleProp) {
        this.loadFundAndAccBal();
      }
    },
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items', 'isVisible']),
    ...mapState({
      nextPaymentNumber: (state) => state.war.transaction.nextPaymentNumber,
      nextWarrantNumber: (state) => state.war.transaction.nextWarrantNumber,
      warFundBalance: (state) => state.war.transaction.warFundBalance,
      warAccountBalance: (state) => state.war.transaction.warAccountBalance,
      transactionTypes: (state) => state.enums.WarTransTypeEnum,
      warTransStatus: (state) => state.enums.WarTransStatusEnum,
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      fundListByFy: (state) => state.war.transaction.fundListByFy,
    }),
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    editedItem: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    amountValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    sumAmount() {
      let totalSum = 0.00;
      for (let i = 0; i < this.editedItem.length; i += 1) {
        totalSum += amountToNumber(this.editedItem[i].amount);
      }
      this.$emit('latestAmount', totalSum);
      return totalSum;
    },
    funds() {
      const fundObjs = this.fundListByFy || [];
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      return [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    departments() {
      const departmentObjs = this.items(departmentCrudKey) || [];
      const updatedDeptObj = departmentObjs.map((d) => ({
        ...d,
        deptDesc: `${d.dept} ${d.description || ''}`,
      }));
      return [{ _id: null, dept: '', deptDesc: '' }, ...updatedDeptObj];
    },
    accounts() {
      const accountObjs = this.items(accountCrudKey) || [];
      const updatedAccObjs = accountObjs.map((a) => ({
        ...a,
        accDesc: `${a.account} ${a.shortDesc || ''}`,
      }));
      return [{ _id: null, account: '', accDesc: '' }, ...updatedAccObjs];
    },
    isFundsOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    headerList() {
      if (this.isFundsOnly) {
        const updatedHeaders = (this.headers || []).filter((h) => h.value !== 'department' && h.value !== 'account');
        return updatedHeaders;
      }
      return this.headers;
    },
    isMultipleFunds() {
      return this.warSettingItem && this.warSettingItem.multipleFunds;
    },
    fundLabel() {
      const balance = this.warFundBalance ? amountFormat(this.warFundBalance) : '...';
      return `Fund (bal before: $${balance})`;
    },
    accountLabel() {
      const balance = this.warAccountBalance ? amountFormat(this.warAccountBalance) : '...';
      return `Account (bal before: $${balance})`;
    },
    isVisibleProp() {
      return this.isVisible(this.baseCrudKey);
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    fundLastSearched: undefined,
    headers: [
      { name: 'fundCol', text: 'Fund', value: 'fund' },
      { name: 'deptCol', text: 'Department', value: 'department' },
      { name: 'accCol', text: 'Account:', value: 'account' },
      { name: 'amtCol', text: 'Amount', value: 'amount' },
      {
        name: 'actCol', text: 'Actions', value: 'actions', sortable: false,
      },
    ],
  }),
  methods: {
    amountFormat,
    amountToNumber,
    focusAmount,
    warStatsBalanceParams,
    loadInitialValues(item) {
      return {
        ...item,
        amount: amountFormat(amountToNumber(item.amount)),
      };
    },
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions('war/transaction', [
      'loadLastWarrantNo',
      'loadLastPaymentNo',
      'warStatsBalance',
    ]),
    ...mapMutations('war/transaction', [
      'setWarrantRegPolling',
    ]),
    determineNextWarrantNo({ fund, fiscalYear }) {
      const loadWarNoPromise = this.loadLastWarrantNo(
        { fund, fiscalYear, baseCrudKey: this.baseCrudKey },
      );
      loadWarNoPromise.then(() => {
        this.$emit('warNo', this.nextWarrantNumber);
      });
    },
    determineNextPaymentNo({ fund, fiscalYear }) {
      const loadPaymentNoPromise = this.loadLastPaymentNo(
        { fund, fiscalYear, baseCrudKey: this.baseCrudKey },
      );
      loadPaymentNoPromise.then(() => {
        this.$emit('paymentNo', this.nextPaymentNumber);
      });
    },
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    shortcutHandler(e, item, index) {
      if (this.isMultipleFunds && isTabOrPlus(e)) {
        if ((index + 1) === (this.editedItem || []).length) {
          for (let i = 0; i < (this.editedItem || []).length; i += 1) {
            this.addOne();
          }
        }
      }
    },
    addOne() {
      this.$emit('input', [
        ...this.editedItem,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.editedItem.filter((candidate) => item !== candidate),
      ]);
    },
    determineNxtTransNos() {
      if (this.activeTransItem.type === 'WARRANTS') {
        this.setWarrantRegPolling(undefined);
        const warrantRegPolling = setInterval(
          this.fetchTransNo,
          1200,
        );
        this.setWarrantRegPolling(warrantRegPolling);
      }
    },
    fetchTransNo() {
      const { fiscalYear } = this.activeTransItem;
      if (Array.isArray(this.editedItem) && this.editedItem.length > 0) {
        const fundId = extractId(this.editedItem[0].fund);
        if (!(checkStringEmptiness(fundId) || checkStringEmptiness(fiscalYear))) {
          this.determineNextWarrantNo(
            {
              fund: fundId,
              fiscalYear,
            },
          );
          if (this.activeTransItem.status === 'PAID') {
            this.determineNextPaymentNo(
              {
                fund: fundId,
                fiscalYear,
              },
            );
          }
        }
      }
    },
    loadFundAndAccBal() {
      const item = (this.editedItem || [])[0];
      if (item && !(this.warSettingItem && this.warSettingItem.multipleFunds)) {
        this.updateStatsBalance(item, warStatsBalanceParams().fundBalance);
        if (!(this.warSettingItem && this.warSettingItem.fundsOnly)) {
          this.updateStatsBalance(item, warStatsBalanceParams().accountBalance);
        }
      }
    },
    updateStatsBalance(item, balanceType) {
      this.warStatsBalance({
        criteria: {
          fund: extractId(item.fund),
          account: extractId(item.account),
          fiscalYear: this.activeTransItem.fiscalYear,
        },
        params: { balanceType },
      });
    },
    onChangeFund() {
      this.determineNxtTransNos();
      /* trigger parent method for action */
      const { enablePrintWarrant } = (this.warSettingItem || {});
      if (enablePrintWarrant) {
        this.$emit('onChangeFund');
      }
    },
  },
};
</script>
