export const headersByType = {
  WARRANTS: [
    {
      text: 'Attachments', value: 'hasFiles', formatter: 'file',
    },
    { text: 'Type', value: 'type', formatter: 'getFirstChar' },
    { text: 'Fund', value: 'fund' },
    { text: 'Department', value: 'department' },
    { text: 'Account', value: 'account' },
    { text: 'War No', value: 'warrant' },
    { text: 'Date', value: 'registerDate' },
    { text: 'To Whom', value: 'whomStr' },
    { text: 'Eft', value: 'isEftCode', formatter: 'boolToStr' },
    {
      text: 'Amount', value: 'amount', formatter: 'amount', align: 'right',
    },
    { text: 'Status', value: 'status', align: 'right' },
    { text: 'Date Paid', value: 'paidCancelledDate' },
    { text: 'Payment No.', value: 'payment', align: 'right' },
  ],
  APPROPRIATIONS: [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, formatter: 'file',
    },
    { text: 'Type', value: 'type', formatter: 'getFirstChar' },
    { text: 'Fund', value: 'fund' },
    { text: 'Department', value: 'department' },
    { text: 'Account', value: 'account' },
    { text: 'Date', value: 'registerDate' },
    { text: 'To Whom', value: 'whomStr' },
    {
      text: 'Amount', value: 'amount', formatter: 'amount', align: 'right',
    },
  ],
  TRANSFERS: [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, formatter: 'file',
    },
    { text: 'Type', value: 'type', formatter: 'getFirstChar' },
    { text: 'Fund', value: 'fund' },
    { text: 'Department', value: 'department' },
    { text: 'Account', value: 'account' },
    { text: 'Date', value: 'registerDate' },
    { text: 'To Whom', value: 'whomStr' },
    {
      text: 'Amount', value: 'amount', formatter: 'amount', align: 'right',
    },
  ],
  SUPPLEMENTS: [
    {
      text: 'Attachments', value: 'hasFiles', sortable: false, formatter: 'file',
    },
    { text: 'Type', value: 'type', formatter: 'getFirstChar' },
    { text: 'Fund', value: 'fund' },
    { text: 'Department', value: 'department' },
    { text: 'Account', value: 'account' },
    { text: 'Date', value: 'registerDate' },
    { text: 'To Whom', value: 'whomStr' },
    {
      text: 'Amount', value: 'amount', formatter: 'amount', align: 'right',
    },
  ],
};

export default headersByType;
