<template>
  <div id="warrant-details">
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="editedItem.status"
          id="edited-item-status"
          :items="warTransStatus"
          item-text="description"
          item-value="name"
          label="Status"
          @change="onStatusChange"
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          v-model="editedItem.isNonPayable"
          label="Non-Payable"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <PaymentNumberInput class="warrant paymentNo"
          v-model.number="editedItem.payment"
          :fund="getFundId"
          :fiscalYear="editedItem.fiscalYear"
          :isNewItem="isNewItem"
          :itemStatus="editedItem.status"
          :baseCrudKey="baseCrudKey"
        />
      </v-col>
      <v-col cols="3">
        <ClarionDateControl
        v-model="editedItem.paidCancelledDate"
         :isIsoDate="true"
         :label="`Paid / Cancelled date`" />
      </v-col>
    </v-row>
    <v-row v-if="isClearingFund">
      <v-col cols="2">
        <v-select
          v-model="editedItem.clearingFundNumber"
          id="edited-item-clearingFundNumber"
          :items="clearingFunds"
          label="Clearing Fund"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import PaymentNumberInput from './PaymentNumberIput.vue';
import WarrantAppropriationsMixin from './WarrantAppropiations.mixin';
import { extractClearingFundNumber } from './warTransaction.util';

export default {
  name: 'WarrantsDetails',
  mixins: [WarrantAppropriationsMixin],
  components: {
    ClarionDateControl,
    PaymentNumberInput,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'war-dialog',
    },
  },
  data: () => ({
    checkbox: true,
  }),
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'index']),
    ...mapGetters(['todaysDate']),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      clearingFunds() {
        const clearingFundNumWithBank = (this.warSettingItem
        && this.warSettingItem.clearingFundList)
         || [];
        const clearingFundNumOnly = extractClearingFundNumber(clearingFundNumWithBank);
        return ['', ...clearingFundNumOnly];
      },
      transactionTypes: (state) => state.enums.WarTransTypeEnum,
      warTransStatus: (state) => state.enums.WarTransStatusEnum,
      nextPaymentNumber: (state) => state.war.transaction.nextPaymentNumber,
    }),
    isClearingFund() {
      return this.warSettingItem && this.warSettingItem.clearingFund;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    getFundId() {
      return this.editedItem.details
      && this.editedItem.details[0]
      && this.editedItem.details[0].fund
      && this.editedItem.details[0].fund._id;
    },
  },
  methods: {
    ...mapMutations('war/transaction', [
      'setWarrantRegPolling',
    ]),
    onStatusChange() {
      if (this.editedItem.status === 'PAID' && !(this.getFundId == null)) {
        this.editedItem.paidCancelledDate = this.todaysDate;
        this.setWarrantRegPolling(undefined);
        const warrantTransPolling = setInterval(
          this.determineNextPaymentNo(
            {
              fund: this.getFundId,
              fiscalYear: this.editedItem.fiscalYear,
            },
          ),
          1200,
        );
        this.setWarrantRegPolling(warrantTransPolling);
      } else if (this.editedItem.status === 'VOID') {
        this.editedItem.paidCancelledDate = null;
        this.updateAmountPaymentNo();
      } else if (this.editedItem.status === 'OUTSTANDING') {
        this.editedItem.paidCancelledDate = null;
        this.editedItem.payment = null;
      } else if (this.editedItem.status === 'CANCELLED') {
        if (!this.editedItem.paidCancelledDate) {
          this.editedItem.paidCancelledDate = this.todaysDate;
        }
        // Todo - cleanup#war-cancelled-amount
        if (this.warSettingItem.cancelledWarrantAmountFlag) {
          // Nothing
        } else {
          this.updateAmountPaymentNo();
        }
      }
    },
    updateAmountPaymentNo() {
      this.editedItem.amount = 0.00;
      this.editedItem.payment = null;
      this.editedItem.details = (this.editedItem.details || []).map((item) => {
        const updatedItem = { ...item };
        updatedItem.amount = 0.00;
        return updatedItem;
      });
    },
  },
};
</script>
