<template>
  <v-container>
    <div v-show="isVisibleProp"></div>
      <WarApprCommon
        :baseCrudKey="baseCrudKey"
        @dialogVal="setDialog"
      />
    <span v-if = "isWarrant">
      <WarrantDetails :baseCrudKey="baseCrudKey"/>
    </span>
    <WarApprDetails
      v-model="editedItem.details"
      @latestAmount="fetchAmount"
      @warNo="fetchWarNo"
      @paymentNo="fetchPaymentNo"
      @onChangeFund="onChangeFund"
      :activeTransItem="editedItem"
      :baseCrudKey="baseCrudKey"
    />
  </v-container>
</template>
<script>

import {
  mapGetters,
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';
import WarApprCommon from './WarApprCommon.vue';
import WarApprDetails from './WarApprDetails.vue';
import WarrantDetails from './WarrantDetails.vue';
import WarrantAppropriationsMixin from './WarrantAppropiations.mixin';
import { amountFormat, amountToNumber } from '../../../util/shared/vue-global';

export default {
  name: 'TransactionEditedItem',
  mixins: [WarrantAppropriationsMixin],
  components: {
    WarApprCommon,
    WarApprDetails,
    WarrantDetails,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'war-dialog',
    },
    activeTransItem: {
      type: Object,
      default: () => {},
    },
    attachmentConstants: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'isVisible']),
    ...mapState({}),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    isVisibleProp() {
      const isVisible = this.isVisible(this.baseCrudKey);
      if (!isVisible) {
        this.setWarrantRegPolling(undefined);
      } else {
        if (this.editedItem._id) {
          this.loadFiles();
        }
        this.loadFundList();
      }
      return isVisible;
    },
  },
  methods: {
    ...mapActions('files', [
      'loadAttachedFiles',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
    ]),
    ...mapMutations('war/transaction', [
      'setWarrantRegPolling',
    ]),
    amountFormat,
    amountToNumber,
    fetchAmount(value) {
      this.editedItem.amount = amountFormat(value);
    },
    fetchWarNo(value) {
      this.editedItem.warrant = value;
    },
    fetchPaymentNo(value) {
      this.editedItem.payment = value;
    },
    loadFiles() {
      this.loadAttachedFiles({
        bucketName: this.attachmentConstants.bucketName,
        program: this.attachmentConstants.program,
        parentObjectType: this.attachmentConstants.parentObjectType,
        parentObjectId: this.editedItem._id,
      });
    },
    setDialog(val) {
      this.dialog = val;
    },
    onChangeFund() {
      const fundNumberList = (this.editedItem.details || []).map((item) => {
        const valueType = Object.prototype.toString.call(item.fund);
        let fundNumber = null;
        if (valueType === '[object Object]') {
          fundNumber = (item.fund || {}).fund;
        } else if (valueType === '[object String]') {
          const fundRecord = this.fundListByFyWise.find((fund) => fund._id === item.fund) || {};
          fundNumber = (fundRecord || {}).fund;
        } else {
          /* do nothing */
        }
        return fundNumber;
      });
      /* trigger parent method for action */
      this.$emit('onChangeFund', fundNumberList);
    },
  },
};
</script>
