<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-bind="attrs" v-on="on"
        v-model="paymentLocal"
        :label="paymentLabel"
        :readonly="!paymentOverride || isNewItem"
        @dblclick.stop="overridePaymentNumber"
        color="red"
        type="number"
        id="payment-number-input"
      ></v-text-field>
    </template>
    <span>{{ paymentHoverCaption }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'PaymentNumberInput',
  props: {
    fund: String,
    fiscalYear: [Number, String],
    payment: [Number, String],
    isNewItem: Boolean,
    itemStatus: {
      type: String,
      default: 'OUTSTANDING',
    },
    baseCrudKey: {
      type: String,
      default: 'maxPaymentNumber',
    },
  },
  data: () => ({
    paymentOverride: false,
  }),
  model: {
    prop: 'payment',
    event: 'paymentchg',
  },
  created() {
    if (this.fund && this.fiscalYear) {
      this.loadLastPaymentNo({
        fund: this.fund,
        fiscalYear: this.fiscalYear,
        baseCrudKey: this.baseCrudKey,
      });
    }
  },
  computed: {
    ...mapState({
      nextPaymentNumber: (state) => state.war.transaction.nextPaymentNumber,
    }),
    paymentLabel() {
      if (this.itemStatus !== 'PAID') {
        return 'Payment # (last: ...)';
      }
      if (this.nextPaymentNumber === undefined) {
        return 'Payment # (last: ...)';
      }
      return `Payment # (last: ${this.nextPaymentNumber - 1})`;
    },
    paymentLocal: {
      get() {
        return this.payment;
      },
      set(value) {
        this.$emit('paymentchg', value);
      },
    },
    paymentHoverCaption() {
      if (this.isNewItem) {
        return 'Payment must be saved before its number can be changed';
      }
      return 'Double-click to override (use ⚠️ caution)';
    },
  },
  methods: {
    ...mapActions('war/transaction', ['loadLastPaymentNo']),
    overridePaymentNumber() {
      if (!this.paymentOverride) {
        this.paymentOverride = true;
      }
    },
  },
};
</script>
