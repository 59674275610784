<template>
  <div class="TransactionList">
    <v-overlay :value="overlay.flag">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="overlay.value"
        color="primary"
      >
        {{ overlay.value }}
      </v-progress-circular>
    </v-overlay>
    <SummaryDialog
      v-model="sumOfTrans.isDisplay"
      :sumOfTrans="sumOfTrans"
    />
    <TransactionCriteria
      @loadCRUDList="handleLoadCRUDList"
      :baseCrudKey="baseCrudKey"
    />
    <CRUDList
      ref="crudList"
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :baseCrudKey="baseCrudKey"
      :headers="headers"
      :uploadFileParams="uploadFileParams"
      :dataMapper="dataMapper"
      :createNewItem="newItemDialog"
      :showSelect="true"
      :showDate="true"
      :showTotal="true"
      :itemTotal="selectedItemsAmount"
      :selectedItems="selectedItems"
      :displayTopPagination="true"
      :defaultSortBy="sortBy"
      :loadingData="gridLoading.flag"
      :loadingText="gridLoading.text"
      @loadCRUDList="handleLoadCRUDList"
      @deleteItem="deleteTransaction"
      @upsertItem="upsertTransaction"
      @emitSelectedItem="emitSelectedItem"
      @activateItem="activateTransaction"
    >
      <template v-slot:[`editedItem`]="{}">
        <TransactionEditedItem
          :baseCrudKey="baseCrudKey"
          :activeTransItem="activeTransItem"
          :attachmentConstants="attachmentConstants"
          @onChangeFund="onChangeEditDialogFund"
        />
      </template>
      <template v-slot:[`additionalActions`]="{}">
        <v-row>
        <v-btn id="warTransPrintFormatBtn"
          class="war-trans-print-format-btn"
          text color="blue darken-1"
          v-if="showWarPrintFormBtn"
          @click="printReceiptFromDialogBox('warPrintForm')">
        Print Warrant</v-btn>
        </v-row>
        <AuditDialog
          v-if="getActiveTransID"
          :identifier="getActiveTransID"
          activatorCaption="Audit History"
        />
        <UploadFile
          class="wartrans uploadFile"
          :bucketName="attachmentConstants.bucketName"
          :program="attachmentConstants.program"
          :parentObjectType="attachmentConstants.parentObjectType"
          :parentObjectId="getActiveTransID || ''"
          :itemIsSaved="!isNewItem"
        ><template v-slot:activator="{ activatorProps }">
        <v-btn
          color="primary"
          text
          @contextmenu.prevent="imagesRightClick(activatorProps.rightClick)"
          @click="imagesLeftClick(activatorProps.leftClick)"
        >
        {{activatorProps.caption}}
        </v-btn>
        </template>
       </UploadFile>
      </template>
      <template v-slot:[`selectDate`]="{}"
        v-if="['WARRANTS', 'APPROPRIATIONS'].includes(this.criteriaState.type)"
      >
        <v-text-field class="mt-6 shrink"
          v-model="markingDateForItems"
          label="Select Date to Mark"
          type="date"
        ></v-text-field>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>

      <template v-slot:[`bulkMarking`]="{}"
        v-if="['WARRANTS'].includes(this.criteriaState.type)"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on,attrs }">
            <v-btn v-bind="attrs" v-on="on"
            class="mx-2" color="primary"
            fab icon small
            :disabled="!canMarkItemsPaidCancel"
            @click="markItemsPaid"
            >
              <v-icon>
                {{icons.mdiAlphaPCircle}}
              </v-icon>
            </v-btn>
          </template>
           <span>Mark Paid</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on,attrs }">
            <v-btn v-bind="attrs" v-on="on"
              class="mx-2" color="primary"
              fab icon small
              :disabled="!canMarkItemsPaidCancel"
              @click="itemsBulkUpdate('CANCELLED')"
            >
              <v-icon>
                {{icons.mdiAlphaCCircle}}
              </v-icon>
            </v-btn>
          </template>
           <span>Mark Cancelled</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on,attrs }">
            <v-btn v-bind="attrs" v-on="on"
              class="mx-2" color="primary"
              fab icon small
              :disabled="canMarkItemsPaidCancel"
              @click="markUnpaid"
            >
              <v-icon>
                {{icons.mdiAlphaUCircle}}
              </v-icon>
            </v-btn>
          </template>
           <span>Mark Unpaid</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on,attrs }">
            <v-btn v-bind="attrs" v-on="on"
              class="mx-2" color="primary"
              fab icon small
              :disabled="!canMarkItemsPaidCancel"
              @click="itemsBulkUpdate('VOID')"
            >
              <v-icon>
                {{icons.mdiAlphaVCircle}}
              </v-icon>
            </v-btn>
          </template>
          <span>Mark Void</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on,attrs }">
            <v-btn v-bind="attrs" v-on="on"
              class="mx-2" color="primary"
              fab icon small
              :disabled="(selectedItems || []).length === 0"
              @click="markNonPayable"
            >
              <v-icon>
                {{icons.mdiAlphaNCircle}}
              </v-icon>
            </v-btn>
          </template>
          <span>Mark Non-Payable</span>
        </v-tooltip>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
      <template v-slot:[`duplicateMarking`]="{}"
        v-if="(criteriaState.type || []).includes('APPROPRIATIONS')"
      >
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"
          >
            <v-icon
              class="mx-2" color="primary"
              fab icon small
              v-bind="attrs"
              v-on="on"
              :disabled="(selectedItems || []).length === 0"
              @click="duplicateAppr"
            >
              {{icons.mdiContentDuplicate}}
            </v-icon>
          </template>
            <span>Duplicate</span>
        </v-tooltip>
      </div>
      <v-divider
          class="mx-4" inset vertical
        ></v-divider>
      </template>
      <template v-slot:[`canDeleteSlot`]="{}"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mx-2" color="primary"
              fab icon small
              v-bind="attrs"
              v-on="on"
              :disabled="(selectedItems || []).length === 0"
              @click="deleteItems"
            >
              {{icons.mdiDelete}}
            </v-icon>
          </template>
            <span>Delete</span>
        </v-tooltip>
      </template>
    </CRUDList>
  </div>
</template>
<script>
import {
  mdiAlphaPCircle,
  mdiAlphaCCircle,
  mdiAlphaUCircle,
  mdiAlphaVCircle,
  mdiAlphaNCircle,
  mdiContentDuplicate,
  mdiDelete,
  mdiPrinter,
} from '@mdi/js';
import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import TransactionCriteria from './TransactionCriteria.vue';
import TransactionEditedItem from './TransactionEditedItem.vue';
import AuditDialog from '../../common/audit/AuditDialog.vue';
import UploadFile from '../../common/UploadFile.vue';
import {
  toFiscalYear,
  getFirstChar,
  toIsoDate,
  calculateFiscalYears,
} from '../../../util/shared/tmc-global';
import {
  amountToNumber,
  extractId,
  isEmptyObject,
  defaultOverlayConfig,
  defaultGridLoading,
  manageGridLoading,
} from '../../../util/shared/vue-global';
import { headersByType } from './transaction.headers';
import WarTransactionList from './WarTransactionList.mixin';
import SummaryDialog from './SummaryDialog.vue';
import {
  getDefaultSumOfTrans,
  programDetails,
} from './warTransaction.util';
import { clients } from '../../../util/clients';

const { backendRest } = clients.direct;

export default {
  name: 'TransactionList',
  mixins: [WarTransactionList],
  components: {
    CRUDList,
    TransactionCriteria,
    TransactionEditedItem,
    AuditDialog,
    UploadFile,
    SummaryDialog,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'transactionList',
    },
  },
  data: () => ({
    defaultTransType: 'WARRANTS',
    activeTransItem: undefined,
    activeEditedItem: {},
    showWarPrintFormBtn: false,
    showTaxReceipt: false,
    markingDateForItems: undefined,
    attachmentConstants: {},
    overlay: defaultOverlayConfig(),
    uploadFileClickParams: {
      leftClickFlag: false,
      leftClickDef: undefined,
      rightClickFlag: false,
      rightClickDef: undefined,
    },
    icons: {
      mdiAlphaPCircle,
      mdiAlphaCCircle,
      mdiAlphaUCircle,
      mdiAlphaVCircle,
      mdiAlphaNCircle,
      mdiContentDuplicate,
      mdiDelete,
      mdiPrinter,
    },
    sortBy: [
      {
        column: 'registerDate',
        direction: 'desc',
      },
    ],
    uploadFileParams: {},
    sumOfTrans: getDefaultSumOfTrans(),
    gridLoading: defaultGridLoading(),
  }),
  created() {
    this.setDefaultData();
    this.setHeadersBySetting(this.defaultTransType);
    this.headersCriteria();
    this.initCriteria();
    this.initializeWarFiscalYears();
    this.fetchAmountTotal();
    this.uploadFileAttachmentParams();
    this.loadConfig({ requiredScope: 'warconfig' }); // Todo - Can remove as warSetting getting load at upper level (in progamList)
  },
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'index']),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem || {},
      headers: (state) => state.war.transaction.headers,
      regYears: (state) => state.war.transaction.regYears,
      nextWarrantNumber: (state) => state.war.transaction.nextWarrantNumber,
      nextPaymentNumber: (state) => state.war.transaction.nextPaymentNumber,
      warrantRegPolling: (state) => state.war.transaction.warrantRegPolling,
      storedSelectedItems: (state) => state.war.transaction.selectedItems,
      registerDateRange: (state) => state.war.transaction.registerDateRange,
      warFundBalance: (state) => state.war.transaction.warFundBalance,
      warTransactionList: (state) => state.base.crud.items.warTransaction,
      fundListByFy: (state) => state.war.transaction.fundListByFy,
    }),
    ...mapGetters(['todaysDate']),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    selectedItems: {
      get() {
        return this.storedSelectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      },
    },
    fiscalYearList() {
      const registeredYears = (this.regYears || {});
      const allowAll = true;
      return calculateFiscalYears(registeredYears, allowAll);
    },
    getActiveTransID() {
      return this.activeTransItem && this.activeTransItem._id;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    canMarkItemsPaidCancel() {
      const hasSelectedItems = (this.selectedItems || []).length > 0;
      const hasDate = (this.markingDateForItems || '').length > 0;
      return hasSelectedItems && hasDate;
    },
    selectedItemsAmount() {
      const amountSum = (this.selectedItems || []).reduce((acc, obj) => acc
        + amountToNumber(obj.amount), 0);
      return this.criteriaState.type === 'WARRANTS' ? (0 - amountSum) : amountSum;
    },
    isInsufficentFundsWarning() {
      return this.warSettingItem && this.warSettingItem.insufficentFundsWarning;
    },
    isMultipleFunds() {
      return this.warSettingItem && this.warSettingItem.multipleFunds;
    },
  },
  methods: {
    getFirstChar,
    programDetails,
    ...mapActions(['reAuth']),
    ...mapMutations('war/transaction', [
      'setHeaders',
      'setWarrantRegPulse',
      'setWarrantRegPolling',
      'setDefaultStats',
      'setSelectedItems',
    ]),
    ...mapMutations('base/crud', ['setCriteria', 'setItem', 'setIndex']),
    ...mapMutations('files', [
      'setFiles',
    ]),
    ...mapActions('war/transaction', [
      'loadTransactionList',
      'delWarTransaction',
      'upsrtTransaction',
      'loadFiscalRegYears',
      'loadLastWarrantNo',
      'loadLastPaymentNo',
      'loadRegisterDateRange',
    ]),
    ...mapActions('shared/fund', ['loadFunds']),
    ...mapActions('war/whom', ['loadWhoms']),
    ...mapActions('SystemConfig', ['loadConfig']),
    handleLoadCRUDList(criteriaArgs = {}) {
      const type = this.criteriaState.type || 'WARRANTS';
      this.setHeadersBySetting(type);
      this.setSortByType();
      const criteria = this.updateCriteriaForReq();
      this.loadTransactionListAndSetDefault({
        baseCrudKey: this.baseCrudKey, criteria, criteriaArgs,
      });
    },
    loadTransactionListAndSetDefault({ baseCrudKey, criteria, criteriaArgs }) {
      manageGridLoading(this, true, 'Searching...');
      this.loadTransactionList({
        baseCrudKey,
        criteria,
      }).then(() => {
        this.setDefaultSelectedItems(criteriaArgs);
      }).catch((e) => {
        console.warn(e);
        this.setDefaultSelectedItems(criteriaArgs);
      }).finally(() => {
        manageGridLoading(this, false);
      });
    },
    async initializeWarFiscalYears() {
      await this.loadFiscalRegYears({ baseCrudKey: this.baseCrudKey });
      const regYears = this.fiscalYearList || [];
      if ((regYears || []).length > 0) {
        this.criteriaState.fiscalYear = (regYears[regYears.length - 1] || {}).year;
      }
      this.handleLoadCRUDList();
      this.loadFundList(this.criteriaState.fiscalYear);
    },
    setHeadersBySetting(transactionType) {
      if (this.warSettingItem && this.warSettingItem.fundsOnly) {
        const allHeaders = headersByType[transactionType];
        const filteredHeaders = allHeaders.filter((h) => h.value !== 'account' && h.value !== 'department');
        this.setHeaders(filteredHeaders);
      } else {
        this.setHeaders(headersByType[transactionType]);
      }
    },
    newItemDialog() {
      this.setDefaultStats();
      this.setFiles([]);
      const newItem = {
        registerDate: this.todaysDate,
        fiscalYear: this.criteriaState.fiscalYear || toFiscalYear(new Date(this.todaysDate)),
        issueDate: this.todaysDate,
        paidCancelledDate: null,
        amount: 0.00,
        warrant: undefined,
        payment: undefined,
        whomStr: '',
        status: 'OUTSTANDING',
        type: '',
        purchaseOrder: null,
        details: [{
          fund: null,
          department: null,
          account: null,
          amount: 0,
        }],
      };
      if (this.criteriaState.status && this.criteriaState.status.length > 0) {
        newItem.status = this.criteriaState.status;
      }
      if (this.criteriaState.type && this.criteriaState.type.length > 0) {
        newItem.type = this.criteriaState.type;
      }
      if (this.criteriaState.type && this.criteriaState.type.length > 0
        && this.criteriaState.type === 'WARRANTS') {
        newItem.status = 'OUTSTANDING';
      }
      if (this.criteriaState.fund && this.criteriaState.fund.length > 0) {
        newItem.details[0].fund = this.criteriaState.fund;
        this.determineTransactionNo();
      }
      if (this.criteriaState.department && this.criteriaState.department.length > 0) {
        newItem.details[0].department = this.criteriaState.department;
      }
      if (this.criteriaState.account && this.criteriaState.account.length > 0) {
        newItem.details[0].account = this.criteriaState.account;
      }
      if (this.warSettingItem && this.warSettingItem.usedLastRegisterDate) {
        this.loadRegisterDateRange({ baseCrudKey: this.baseCrudKey }).then(() => {
          setTimeout(() => {
            newItem.registerDate = new Date(this.registerDateRange.max) > new Date()
              ? this.registerDateRange.max : this.todaysDate;
            newItem.issueDate = newItem.registerDate;
          }, 500);
        }).catch((e) => {
          console.warn(e);
          newItem.registerDate = this.todaysDate;
          newItem.issueDate = this.todaysDate;
        });
      }
      return newItem;
    },
    loadFundList(fiscalYear) {
      const currentFiscalYear = fiscalYear || toFiscalYear(new Date(this.todaysDate));
      this.loadFunds({
        criteria: {
          fiscalYear: currentFiscalYear,
          sortBy: [
            {
              column: 'fund',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: 'sharedFund',
      });
    },
    deleteTransaction(item) {
      const { baseCrudKey } = this;
      this.delWarTransaction({ item, baseCrudKey }).then(() => {
        this.setDefaultSelectedItems();
      }).catch((e) => {
        console.warn(e);
        this.setDefaultSelectedItems();
      });
    },
    async printReceiptFromDialogBox(receiptName) {
      const isValidForm = this.$refs.crudList.$refs.crudDialog.validateForm();
      if (isValidForm) {
        await this.upsertTransaction(this.editedItem);
        this.printReceipt(receiptName, this.getActiveTransID);
      }
    },
    async printReceipt(receiptType, warTransId) {
      const { jwt } = await this.reAuth();
      let url;
      if (receiptType === 'warPrintForm') {
        url = `${backendRest.defaults.baseURL}/war/warPrintForm?warTransId=${warTransId}&token=${jwt}`;
      }
      /* for more reports feel free to add more links */
      window.open(url, '_blank');
    },
    async upsertTransaction(item, isBulkAction) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      delete updatedItem.hasFiles;
      if (updatedItem.paidCancelledDate === '' || updatedItem.status === 'OUTSTANDING') {
        updatedItem.paidCancelledDate = null;
      }
      if (updatedItem.details !== undefined && updatedItem.details.length > 0) {
        updatedItem.details = updatedItem.details.map((element) => {
          const amount = amountToNumber(element.amount);
          const detailItem = { ...element };
          if (detailItem.fund) {
            detailItem.fund = extractId(detailItem.fund);
          }
          if (detailItem.account) {
            detailItem.account = extractId(detailItem.account);
          }
          if (detailItem.department) {
            detailItem.department = extractId(detailItem.department);
          }
          return { ...detailItem, amount };
        });
      }
      let response;
      if (!isBulkAction && this.checkInsufficentFundsWarning(updatedItem)) {
        const fundNumber = this.getFundNumber(updatedItem.details[0].fund);
        if (window.confirm('Warning: This Warrant Transaction will result in a negative balance'
            + ` for Fund ${fundNumber || ''}. Do you wish to proceed?`)) {
          /*
            Trying to reduce the chance of skipping warrant number,
            stopping polling just before upserting #stopWarPolling
          */
          this.setWarrantRegPolling(undefined);
          response = await this.upsrtTransaction({ item: updatedItem, baseCrudKey });
        }
      } else {
        response = await this.upsrtTransaction({ item: updatedItem, baseCrudKey });
      }
      if (!isEmptyObject(response)) {
        this.updateActiveTransItem(response);
      }
      this.setDefaultSelectedItems();
    },
    settingFields(res) {
      // updating active trans item with new res containing id
      this.activeTransItem = res;
      /* No need of this as 'upsrtTransaction' in the store already updating store value
      // updating the item using res and basecrudkey
      this.setItem([this.baseCrudKey, res]);
       */
      // making it edit item from new item by setting index
      this.setIndex([this.baseCrudKey, 0]);
      /* TODO - remove may be not required already avaible in #stopWarPolling */
      this.setWarrantRegPolling(undefined);
    },
    updateActiveTransItem(res) {
      this.settingFields(res);
      if (this.uploadFileClickParams.leftClickFlag) {
        this.$nextTick(() => {
          this.imagesLeftClick(this.uploadFileClickParams.leftClickDef);
          this.uploadFileClickParams.leftClickFlag = false;
          this.uploadFileClickParams.leftClickDef = undefined;
        });
      } else if (this.uploadFileClickParams.rightClickFlag) {
        this.$nextTick(() => {
          this.imagesRightClick(this.uploadFileClickParams.rightClickDef);
          this.uploadFileClickParams.rightClickFlag = false;
          this.uploadFileClickParams.rightClickDef = undefined;
        });
      }
      return false;
    },
    dataMapper(dataItems) {
      const headersField = [];
      for (let i = 0; i < dataItems.length; i += 1) {
        headersField.push(dataItems[i]);
        if (Array.isArray(dataItems[i].details) && dataItems[i].details.length > 0) {
          if (dataItems[i].details.length === 1) {
            if (!(dataItems[i].details[0].fund == null)) {
              headersField[i].fund = dataItems[i].details[0].fund.fund;
            }
            if (!(dataItems[i].details[0].department == null)) {
              headersField[i].department = dataItems[i].details[0].department.dept;
            }
            if (!(dataItems[i].details[0].account == null)) {
              headersField[i].account = dataItems[i].details[0].account.account;
            }
          }
        }
      }
      return headersField;
    },
    activateTransaction(val) {
      this.activeTransItem = val;
    },
    determineTransactionNo() {
      if (this.criteriaState.type === 'WARRANTS') {
        const warrantRegPolling = setInterval(
          this.determineNextWarrantNo,
          1200,
        );
        this.setWarrantRegPolling(warrantRegPolling);
      }
    },
    determineNextWarrantNo() {
      if (!(this.criteriaState.fund == null)) {
        const loadWarNoPromise = this.loadLastWarrantNo(
          {
            fund: this.criteriaState.fund,
            fiscalYear: this.criteriaState.fiscalYear,
            baseCrudKey: this.baseCrudKey,
          },
        );
        loadWarNoPromise.then(() => {
          let animate = false;
          if (this.editedItem.warrant !== this.nextWarrantNumber) {
            animate = true;
          }
          this.editedItem.warrant = this.nextWarrantNumber;
          if (animate) {
            this.$nextTick(() => {
              this.setWarrantRegPulse(true);
            });
          } else {
            this.setWarrantRegPulse(false);
          }
        });
      }
    },
    async imagesLeftClick(leftClick) {
      if (this.isNewItem) {
        await this.$refs.crudList.$refs.crudDialog.apply();
        this.uploadFileClickParams.leftClickFlag = true;
        this.uploadFileClickParams.leftClickDef = leftClick;
      } else {
        leftClick();
      }
    },
    async imagesRightClick(rightClick) {
      if (this.isNewItem) {
        await this.$refs.crudList.$refs.crudDialog.apply();
        this.uploadFileClickParams.rightClickFlag = true;
        this.uploadFileClickParams.rightClickDef = rightClick;
      } else {
        rightClick();
      }
    },
    emitSelectedItem(item) {
      this.selectedItems = item;
    },
    warMustOSForChangingStatus(item) {
      if (item.type === 'WARRANTS') {
        return item.status === 'OUTSTANDING';
      }
      return true;
    },
    async fetchUnpaidTrans() {
      const isoData = toIsoDate(this.markingDateForItems);
      const params = {
        status: 'PAID',
        paidCancelledDate: isoData,
        type: 'WARRANTS',
      };
      return this.loadTransactionList({
        criteria: params,
        programKey: this.baseCrudKey,
      });
    },
    async deleteItems() {
      if (window.confirm(`CAREFUL! Are you sure you want to DELETE ${this.selectedItems.length} record/s?`)) {
        for (let i = 0; i < this.selectedItems.length; i += 1) {
          const item = this.selectedItems[i];
          this.deleteTransaction(item);
        }
      }
      this.selectedItems = [];
    },
    uploadFileAttachmentParams() {
      const { attachement } = this.programDetails(this.baseCrudKey);
      this.attachmentConstants = attachement;
      this.uploadFileParams = attachement;
    },
    getFundNumber(selectedFundId) {
      const fundObjs = this.fundListByFy || [];
      const fundObjResult = fundObjs.find((fundElement) => fundElement._id === selectedFundId);
      let fundNumberRes = null;
      if (fundObjResult) {
        fundNumberRes = fundObjResult.fund;
      }
      return fundNumberRes;
    },
    checkInsufficentFundsWarning(transItem) {
      if (this.isInsufficentFundsWarning && transItem.type === 'WARRANTS' && !this.isMultipleFunds) {
        const fundAmount = transItem.details[0].amount;
        let newBalance = amountToNumber(this.warFundBalance) - (fundAmount || 0.0);
        if (!this.isNewItem) {
          const warTransactionsArr = this.warTransactionList || [];
          const trans = warTransactionsArr.find((t) => t._id === transItem._id);
          if (trans) {
            const existingFundAmt = trans.details[0].amount;
            // Give warning only if new amount is affecting fund balance.
            if (fundAmount === existingFundAmt) return false;
            newBalance += amountToNumber(existingFundAmt);
          }
        }
        return newBalance <= 0;
      }
      return false;
    },
  },
};
</script>
